/**
 * @generated SignedSource<<e1e41e440da24b5f5c235f8b8140e23c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbSharedRecentlySoldItems_viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SharedItemTile_viewer" | "useSbSharedItemTracking_viewer">;
  readonly " $fragmentType": "SbSharedRecentlySoldItems_viewer";
};
export type SbSharedRecentlySoldItems_viewer$key = {
  readonly " $data"?: SbSharedRecentlySoldItems_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedRecentlySoldItems_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbSharedRecentlySoldItems_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharedItemTile_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useSbSharedItemTracking_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "c0662e784f34e4ad2c2805097ddf1ad4";

export default node;
