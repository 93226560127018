import { defineMessages, useIntl } from 'dibs-react-intl';
import PropTypes from 'prop-types';
import * as tracking from 'dibs-tracking';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { getNumberOfTiles } from '../../utils/adHelpers';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { updateRelayVariables } from '../../actions/sharedRelayVariableActions';
import { ServerVarsContextConsumer } from '../../global/ServerVarsContext/ServerVarsContext';
import {
    isLatestPage,
    getSelectedValue,
    setSelectedValue,
    getPageSizeSelectOptions,
} from './sbRespPageSizeHelpers';

// components
import { BasicSelect } from 'dibs-elements/exports/BasicSelect';

// styles
import styles from './SbRespPageSize.scss';

const messages = defineMessages({
    label: { id: 'sb.SbRespPageSizeComponent.label', defaultMessage: 'Items per page' },
});

function trackPageSizeEvent(pageSizeValue) {
    tracking.trackEvent({
        category: 'results page refinement',
        action: 'results displayed',
        label: `${pageSizeValue} results clicked`,
        value: 0,
    });
}

function PageSizeLinks({ options, onChange, pageSize, isSbAdsEnabled }) {
    return options.map(({ value }) => {
        const isSelected = getSelectedValue(pageSize) === value;
        return (
            <span
                key={value}
                className={classNames(styles.link, { [styles.selected]: isSelected })}
                onClick={() => {
                    if (!isSelected) {
                        onChange({ target: { value } }, isSbAdsEnabled);
                    }
                }}
            >
                {value}
            </span>
        );
    });
}

export function SbRespPageSizeComponent({
    onChange = () => {},
    pageSize,
    itemSearch,
    usePageSizeLinks,
}) {
    const intl = useIntl();
    const { appliedFilters } = itemSearch;
    const options = getPageSizeSelectOptions(intl);

    if (isLatestPage(appliedFilters)) {
        return null;
    }

    return (
        <ServerVarsContextConsumer>
            {({ isSbAdsEnabled }) => {
                return usePageSizeLinks ? (
                    <PageSizeLinks
                        pageSize={pageSize}
                        onChange={onChange}
                        options={options}
                        isSbAdsEnabled={isSbAdsEnabled}
                    />
                ) : (
                    <div className={styles.container}>
                        <BasicSelect
                            ariaLabel={intl.formatMessage(messages.label)}
                            size="medium"
                            options={options}
                            onChange={e => onChange(e, isSbAdsEnabled)}
                            dataTn="sb-resp-page-size"
                            value={getSelectedValue(pageSize)}
                        />
                    </div>
                );
            }}
        </ServerVarsContextConsumer>
    );
}

SbRespPageSizeComponent.propTypes = {
    onChange: PropTypes.func.isRequired,
    pageSize: PropTypes.number,
    itemSearch: PropTypes.object.isRequired,
    usePageSizeLinks: PropTypes.bool,
};

const mapStateToProps = state => {
    const { relayVariables } = state;
    const {
        variables: { first },
    } = relayVariables;
    return {
        pageSize: first,
    };
};

function mapDispatchToProps(dispatch, props) {
    return {
        onChange(e, isSbAdsEnabled) {
            const { itemSearch } = props;
            const { displayUriRef } = itemSearch;

            // set page size in cookies
            setSelectedValue(e.target.value);

            // track event in GA
            trackPageSizeEvent(e.target.value);

            // update relay variables
            dispatch(
                updateRelayVariables({
                    first: getNumberOfTiles({
                        pageSize: parseInt(e.target.value, 10),
                        path: displayUriRef,
                        isSbAdsEnabled,
                    }),
                })
            );
        },
    };
}

export const SbRespPageSize = createFragmentContainer(
    connect(mapStateToProps, mapDispatchToProps)(SbRespPageSizeComponent),
    {
        itemSearch: graphql`
            fragment SbRespPageSize_itemSearch on ItemSearchQueryConnection {
                appliedFilters {
                    name
                    values {
                        urlLabel
                    }
                }
                displayUriRef
            }
        `,
    }
);
