/**
 * @generated SignedSource<<6bf9dee27efaa285e3c3e8399cc15858>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbSharedRecommendedItems_viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"RecommendedItemsCarousel_viewer">;
  readonly " $fragmentType": "SbSharedRecommendedItems_viewer";
};
export type SbSharedRecommendedItems_viewer$key = {
  readonly " $data"?: SbSharedRecommendedItems_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedRecommendedItems_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbSharedRecommendedItems_viewer",
  "selections": [
    {
      "args": [
        {
          "kind": "Literal",
          "name": "returnAmount",
          "value": 12
        }
      ],
      "kind": "FragmentSpread",
      "name": "RecommendedItemsCarousel_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "b104870af747a8b75e7bcb06b9ba4597";

export default node;
