/**
 * @generated SignedSource<<4e087676ef24d3290008823c73e325e5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbRespRefineMenu_itemSearch$data = {
  readonly displayUriRef: string | null;
  readonly pageType: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedRefineMenuDisplayPrefsSelect_itemSearch" | "SbSharedRefineMenuFilters_itemSearch" | "SbSharedRefineMenuSearchWithin_itemSearch">;
  readonly " $fragmentType": "SbRespRefineMenu_itemSearch";
};
export type SbRespRefineMenu_itemSearch$key = {
  readonly " $data"?: SbRespRefineMenu_itemSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbRespRefineMenu_itemSearch">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbRespRefineMenu_itemSearch",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayUriRef",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pageType",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuFilters_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuDisplayPrefsSelect_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuSearchWithin_itemSearch"
    }
  ],
  "type": "ItemSearchQueryConnection",
  "abstractKey": null
};

(node as any).hash = "76c85febf224da955e34a5192d036cd7";

export default node;
