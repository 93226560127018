/**
 * @generated SignedSource<<466a3c70aec935acb2c8e4f99d08ee32>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbSharedPersonalizedFilterToast_shippingFilters$data = ReadonlyArray<{
  readonly totalCount: number | null;
  readonly " $fragmentType": "SbSharedPersonalizedFilterToast_shippingFilters";
}>;
export type SbSharedPersonalizedFilterToast_shippingFilters$key = ReadonlyArray<{
  readonly " $data"?: SbSharedPersonalizedFilterToast_shippingFilters$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedPersonalizedFilterToast_shippingFilters">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "SbSharedPersonalizedFilterToast_shippingFilters",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    }
  ],
  "type": "ShippingFilterType",
  "abstractKey": null
};

(node as any).hash = "62ccccb1a602b84a1bef0784594396d5";

export default node;
