/**
 * @generated SignedSource<<40afa2c9373c25fab3d3cdb742c78210>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbRespNullSearchResults_itemSearch$data = {
  readonly soldItems: {
    readonly totalResults: number | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"SbRespFollowSearchBanner_itemSearch" | "SbSharedControlledHeadingWrapper_itemSearch" | "SbSharedPageBreadcrumbs_itemSearch" | "SbSharedTitle_itemSearch" | "SharedFollowSearchBanner_itemSearch" | "SharedFollowTopButton_itemSearch" | "SharedRecentSales_itemSearch" | "SharedYMAL_itemSearch">;
  readonly " $fragmentType": "SbRespNullSearchResults_itemSearch";
};
export type SbRespNullSearchResults_itemSearch$key = {
  readonly " $data"?: SbRespNullSearchResults_itemSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbRespNullSearchResults_itemSearch">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "priceBookName"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbRespNullSearchResults_itemSearch",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedPageBreadcrumbs_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbRespFollowSearchBanner_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharedFollowSearchBanner_itemSearch"
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "priceBookName",
          "variableName": "priceBookName"
        }
      ],
      "kind": "FragmentSpread",
      "name": "SharedYMAL_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharedFollowTopButton_itemSearch"
    },
    {
      "alias": "soldItems",
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 28
        }
      ],
      "concreteType": "soldResultsConnection",
      "kind": "LinkedField",
      "name": "soldResults",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalResults",
          "storageKey": null
        }
      ],
      "storageKey": "soldResults(first:28)"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharedRecentSales_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedTitle_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedControlledHeadingWrapper_itemSearch"
    }
  ],
  "type": "ItemSearchQueryConnection",
  "abstractKey": null
};

(node as any).hash = "10a864324810af8b6060922f631ca66c";

export default node;
