/**
 * @generated SignedSource<<f40877a21476ae63caca4802dd602e8b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbRespMainContent_viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SbRespRefineMenuSelector_viewer" | "SbRespSearchResultContainer_viewer" | "SbSharedRecentlySoldItems_viewer" | "SbSharedRelatedItems_viewer">;
  readonly " $fragmentType": "SbRespMainContent_viewer";
};
export type SbRespMainContent_viewer$key = {
  readonly " $data"?: SbRespMainContent_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbRespMainContent_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbRespMainContent_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbRespRefineMenuSelector_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbRespSearchResultContainer_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRelatedItems_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRecentlySoldItems_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "3a9937fc64fb03a3c40f7425fe270fc4";

export default node;
