import { useId, type FC } from 'react';
import LazyImage from 'dibs-react-lazy-image';
import { getSrcSetString } from 'dibs-search-product-tile/exports/srcSetHelpers';
import {
    trackInitialDisplay,
    trackArrowClick,
} from 'dibs-recent-history/exports/recentHistoryTracking';
import { Link } from 'dibs-elements/exports/Link';
import { type ItemType } from 'dibs-recent-history/exports/types';

import { TrackingCarousel } from '../../components/global/TrackingCarousel';
import {
    HORIZONTAL_OFFSET_RESP_IMG,
    VERTICAL_OFFSET_RESP_IMG,
    SRC_SET_SIZES_RESP_CAROUSEL_TILE,
} from '../../constants/imageConstants';
import {
    type ClassNames,
    type ItemClick,
    type ItemImpression,
} from '../SharedCarouselWrapper/SharedCarouselWrapper';

const handleInitialDisplay = (items: ItemType[], actionLabel: string): void => {
    const itemsReturned = items.length.toString();
    trackInitialDisplay({ itemsReturned, actionLabel });
};

export type CarouselType = 'viewed' | 'saved';

type Props = {
    items: ItemType[];
    itemsPerPage: number;
    classNames: ClassNames;
    carouselType: CarouselType;
    actionLabel: string;
    onItemClick?: ({ item, index, actionLabel }: ItemClick) => void;
    onItemImpression?: ({ items, index, actionLabel }: ItemImpression) => void;
};

export const SharedCarousel: FC<Props> = ({
    items,
    itemsPerPage,
    classNames,
    carouselType,
    actionLabel,
    onItemClick = () => {},
    onItemImpression,
}) => {
    const htmlId = useId();

    // this is not a standard react component function--do not force proptype validation!
    // eslint-disable-next-line react/prop-types
    const renderCarouselItem: FC<{ index: number }> = ({ index }) => {
        const item = items[index];
        const itemImageUrlSrc = item.itemImageUrl;

        const srcSetAttributes = itemImageUrlSrc
            ? {
                  srcSet: getSrcSetString(itemImageUrlSrc),
                  sizes: SRC_SET_SIZES_RESP_CAROUSEL_TILE,
              }
            : {};
        const key = `recent-${item.itemId}`;
        const id = `${htmlId}-recent-${item.itemId}`;
        const dataTn = `recently-${carouselType}-link`;
        return (
            <Link
                key={key}
                id={id}
                href={item.itemPdpUrl}
                onClick={() => onItemClick({ item, index, actionLabel })}
                className={classNames.itemLink}
                dataTn={dataTn}
                // use link label instead of img alt bc lazy img will not be rendered on page load
                // therefor alt text/ will not exist on page load.
                ariaLabel={item.itemTitle}
            >
                <LazyImage
                    offsetHorizontal={HORIZONTAL_OFFSET_RESP_IMG}
                    offsetVertical={VERTICAL_OFFSET_RESP_IMG}
                    placeholderClass={classNames.placeholder}
                    className={classNames.placeholder}
                >
                    <img
                        aria-labelledby={id}
                        className={classNames.itemImage}
                        src={itemImageUrlSrc}
                        alt=""
                        {...srcSetAttributes}
                    />
                </LazyImage>
            </Link>
        );
    };

    return (
        <TrackingCarousel
            items={items}
            classNames={classNames}
            dataTn={`recently-${carouselType}-carousel`}
            renderItem={renderCarouselItem}
            itemsPerPage={itemsPerPage}
            onInitialDisplay={handleInitialDisplay(items, actionLabel)}
            onItemImpression={onItemImpression}
            onNextPage={() => trackArrowClick({ direction: 'right', actionLabel })}
            onPreviousPage={() => trackArrowClick({ direction: 'left', actionLabel })}
            actionLabel={actionLabel}
        />
    );
};
