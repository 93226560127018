/**
 * @generated SignedSource<<d202be65108204eecbdd96222de03651>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbSharedRefetchContainer_regionalInfo$data = ReadonlyArray<{
  readonly regionsByZipCode: ReadonlyArray<{
    readonly displayName: string | null;
    readonly urlLabel: string | null;
  } | null> | null;
  readonly " $fragmentType": "SbSharedRefetchContainer_regionalInfo";
}>;
export type SbSharedRefetchContainer_regionalInfo$key = ReadonlyArray<{
  readonly " $data"?: SbSharedRefetchContainer_regionalInfo$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedRefetchContainer_regionalInfo">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "SbSharedRefetchContainer_regionalInfo",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "RegionsByZipCode",
      "kind": "LinkedField",
      "name": "regionsByZipCode",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "urlLabel",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "RegionalInfo",
  "abstractKey": null
};

(node as any).hash = "c3f7ac30a90500c6a81ef4813b5a69f4";

export default node;
