/**
 * @generated SignedSource<<7a8921970e6bd496ced9f7fd969a62cc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbRespMainContent_user$data = {
  readonly canShowInternalSellerName: boolean | null;
  readonly canShowSellerName: boolean | null;
  readonly canShowStorefrontOnlyBadge: boolean | null;
  readonly " $fragmentSpreads": FragmentRefs<"SbRespAppliedFilters_user" | "SbRespRefineMenuSelector_user" | "SbRespSearchResultContainer_user" | "SbRespSortItems_user" | "SharedFollowTopButton_user">;
  readonly " $fragmentType": "SbRespMainContent_user";
};
export type SbRespMainContent_user$key = {
  readonly " $data"?: SbRespMainContent_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbRespMainContent_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbRespMainContent_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canShowSellerName",
      "storageKey": null
    },
    {
      "alias": "canShowInternalSellerName",
      "args": [
        {
          "kind": "Literal",
          "name": "name",
          "value": "VIEW_SELLER_NAME"
        }
      ],
      "kind": "ScalarField",
      "name": "hasPermission",
      "storageKey": "hasPermission(name:\"VIEW_SELLER_NAME\")"
    },
    {
      "alias": "canShowStorefrontOnlyBadge",
      "args": [
        {
          "kind": "Literal",
          "name": "name",
          "value": "VIEW_STOREFRONT_ONLY_BADGE"
        }
      ],
      "kind": "ScalarField",
      "name": "hasPermission",
      "storageKey": "hasPermission(name:\"VIEW_STOREFRONT_ONLY_BADGE\")"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbRespRefineMenuSelector_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbRespSearchResultContainer_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbRespAppliedFilters_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbRespSortItems_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharedFollowTopButton_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "1359f0dab29573d26d5156dd16d8af71";

export default node;
