/**
 * @generated SignedSource<<451c66a8f9b64748f89959664b28f9b4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbRespRefineMenu_viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedRefineMenuFilters_viewer">;
  readonly " $fragmentType": "SbRespRefineMenu_viewer";
};
export type SbRespRefineMenu_viewer$key = {
  readonly " $data"?: SbRespRefineMenu_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbRespRefineMenu_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbRespRefineMenu_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuFilters_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "dfa896631ef0486d7c780a56a0395f64";

export default node;
