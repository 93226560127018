import { type FC } from 'react';
import { CategoryTiles, type OnClickFunction } from 'dibs-buyer-layout/exports/CategoryLinks';
import {
    trackL1ModuleClick,
    trackL2ModuleClick,
    trackCreatorModuleClick,
} from '../../utils/tracking/searchBrowse/nullResultsTracking';

const CREATOR_CATEGORY_TYPE = 'tile';

const onL1ModuleClick: OnClickFunction = (e, { vertical }) => {
    const l1Name = vertical?.category;

    if (vertical.type === CREATOR_CATEGORY_TYPE) {
        return trackCreatorModuleClick(l1Name);
    }
    return trackL1ModuleClick(l1Name);
};

const onL2ModuleClick: OnClickFunction = (e, { vertical, index }) =>
    trackL2ModuleClick(vertical.category, index === 0);

export const SbRespNullSearchResultsTiles: FC = () => {
    return <CategoryTiles onMainTileClick={onL1ModuleClick} onModuleTileClick={onL2ModuleClick} />;
};
