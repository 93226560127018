/**
 * @generated SignedSource<<2a1b6355aa71a8834eea2d34d7bc4e35>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbRespNullSearchResults_viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedRecommendedItems_viewer" | "SharedRecentSales_viewer">;
  readonly " $fragmentType": "SbRespNullSearchResults_viewer";
};
export type SbRespNullSearchResults_viewer$key = {
  readonly " $data"?: SbRespNullSearchResults_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbRespNullSearchResults_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbRespNullSearchResults_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRecommendedItems_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharedRecentSales_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "08c18dd2e8bc3a020268d3ac39af45e1";

export default node;
